import React from "react";
import {Link} from "gatsby";
import Layout from "../components/layout";
import "../styles/pages/success.scss";

const IndexPage = () => (
    <Layout>
        <section className="success">
            <div className="inner">
                <h1>Thank you,</h1>
                <p>Message sent successfully! <Link to="/"> Go back to the home page.</Link></p>
            </div>
        </section>
    </Layout>
)

export default IndexPage